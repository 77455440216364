import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`The Key to Iterating on NLP Models`}</h2>
    <p>{`With Dashbot’s new Test before Deploy functionality, you can test your NLP Model with past data to see the results of making changes.`}</p>
    <p>{`Building and maintaining an NLP model can be challenging. It is hard to know all the things a user may ask your chatbot, or the all the ways they may ask. We consistently hear from customers that optimizing the NLP model is one of the most challenging aspects of chatbot development.`}</p>
    <p>{`Dashbot is here to help.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2020/05/26185908/screenshot-75b.png",
        "alt": null
      }}></img>{` The Test Before Deploy functionality helps provide an indication of the impact on changes to your underlying model.`}</p>
    <p>{`Simply upload your model, or two versions of the model for a comparison report, select a start date for prior data, and see the results.`}</p>
    <p>{`You can continue to iterate on your model and retest variations to see the results. Once you are ready, you can deploy the model live.`}</p>
    <p>{`Dashbot’s Test Before Deploy functionality integrates with our Model Assessment. With the Model Assessment, you can get feedback and recommendations on improvements to Intents and training phrases. After accepting recommendations, use the Test Before Deploy tool to see the results.`}</p>
    <p>{`Testing is a critical step to Iterating your NLP Model. With Dashbot’s Test Before Deploy, you can take the guesswork out.`}</p>
    <p>{`Test Before Deploy currently only supports DialogFlow models. Stay turned for additional platform support.`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is an analytics platform for conversational interfaces that enables enterprises to increase engagement, satisfaction, and conversion through actionable insights and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we provide chatbot specific analytics including NLP response effectiveness, sentiment analysis, conversation flows, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our NLP Optimization tools, Phrase Clustering, NLP Assessments, and Live Person Take Over of chat sessions.`}</p>
    <p>{`We support Alexa, Google Assistant, Web Chat, SMS, Facebook Messenger, Slack, Twitter, Bixby, and any other conversational interface.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      